import * as React from 'react'
import { SUBSCRIPTIONS } from '../../constants/subscriptions'

export const SubscriptionCards = (props: any) => {
    return (
        <div className="row text-center wow fadeIn">
            <SubscriptionCard data={SUBSCRIPTIONS[0]} chargebeeData={props.chargebeeData} handleClick={props.handleSubscription} />
            <SubscriptionCard data={SUBSCRIPTIONS[1]} chargebeeData={props.chargebeeData} handleClick={props.handleSubscription} />
            <SubscriptionCard data={SUBSCRIPTIONS[2]} chargebeeData={props.chargebeeData} handleClick={props.handleSubscription} />
        </div>
    )
}

// Single card
const SubscriptionCard = (props: any) => {
    const { data, chargebeeData, handleClick } = props

    const isCurrentActivePlan = ():boolean => {
        switch (true) {
            case !chargebeeData.subscription:
                return false;
            case chargebeeData.subscription.plan_id === data.id && chargebeeData.subscription.status === "active":
                return true;
            default:
                return false;
        }
    }

    // tslint:disable-next-line: no-console
    console.log('chargebeeData :', chargebeeData, isCurrentActivePlan());

    return (
        <div className="col-lg-4 col-md-12 mb-4">
            <div className="card shadow">
                <div className="card-header">
                    <h4 className="my-0">{data.prettyName} {isCurrentActivePlan() ? <small>- your subscription</small> : null}</h4>
                </div>
                <div className="card-body">
                    <h3 className="mb-4">${data.price / 100}<small className="text-muted">/ mo</small></h3>

                    <ol className="list-unstyled mb-4">
                        <li>10 users included
                                    <i className="fas fa-check ml-1" />
                        </li>
                        <hr />
                        <li>2 GB of storage
                                    <i className="fas fa-check ml-1" />
                        </li>
                        <hr />
                        <li>Email support
                                    <i className="fas fa-check ml-1" />
                        </li>
                        <hr />
                        <li>Help center access
                                    <i className="fas fa-check ml-1" />
                        </li>
                    </ol>
                    {
                        isCurrentActivePlan()
                            ? <button onClick={() => handleClick(data.id)} type="button" className="btn btn-lg btn-block btn-danger">Cancel</button>
                            : <button onClick={() => handleClick(data.id)} type="button" className="btn btn-lg btn-block btn-primary">Subscribe</button>
                    }

                </div>
            </div>
        </div>
    )
}

export default SubscriptionCards
