import * as React from 'react';

export const NoMatch = ({ history, location, match }: { [key: string]: any }) => (
  <div className="row">
    { history.location.pathname }
    <hr />
    {history.location.length}
    <hr />
    {history.location.pathname}
    <hr />
    {history.location.pathname}
    <hr />
    <h1>Hello world</h1>
  </div>
);
