import axios from 'axios'
import { IChargebeeDatabaseData } from '../types'


/**
 * Takes the chargebee data and
 * @function handleSubscribe
 * @param IChargebeeDatabaseData - from main application state
 * @param planId - string that corresponds with the chargebee subscription
 * @returns {url} URL for the iframe to open
 */
export const getSubscriptionUrl = async (chargebeeData: IChargebeeDatabaseData, planId: string) :Promise<(string | any)> => {
    const chargeBeeUrl = isUsersCurrentPlan(chargebeeData, planId) ?
        'https://us-central1-banner-preview-typescript.cloudfunctions.net/generateCheckoutExistingUrl' :
        'https://us-central1-banner-preview-typescript.cloudfunctions.net/generateCheckoutExistingUrl'

    const requestConfig = {
        params: {
            chargebee_subscription_id: chargebeeData.customer.id,
            embed: true,
            iframe_messaging: true,
            plan_id: planId,
        },
    }

    // tslint:disable-next-line: no-console
    console.log('requestConfig :', requestConfig);

    const response = await axios.get(chargeBeeUrl, requestConfig)
    if (response instanceof Error) {
        throw new Error(response.message)
    }

    const responseUrl = ():string | any => {
        const { url, access_url }: { url: string | null, access_url: string | null } = response.data
        if (url) {
            return url
        } else if (access_url) {
            return access_url
        } else {
            throw new Error('Did not get a url back from Chargebee');
        }
    }

    return responseUrl()
}

export const isUsersCurrentPlan = (chargebeeData: IChargebeeDatabaseData , planId: any) => {
    const { plan_id, status } = chargebeeData.subscription.plan_id
    // tslint:disable-next-line: no-console
    console.log('plan_id, id', plan_id, planId)
    if (planId === plan_id && status === "active") {
        // tslint:disable-next-line: no-console
        console.log('IS USERS CURRENT PLAN!')
        return true
    } else {
        // tslint:disable-next-line: no-console
        console.log('NOT USERS CURRENT PLAN!')
        return false
    }
}

export const handlePortal = async (firestoreUser: any) => {
    const chargeBeeUrl = 'https://us-central1-banner-preview-typescript.cloudfunctions.net/generatePortalSession'
    const { chargebee_subscription_id } = firestoreUser.data
    const requestConfig = {
        params: {
            chargebee_subscription_id,
            embed: true,
            iframe_messaging: true,
            plan_id: 'cbdemo_scale',
        },
    }

    const response = await axios.get(chargeBeeUrl, requestConfig)
    if (response instanceof Error) {
        throw new Error(response.message)
    }

    const { data } = response;
    const { access_url } = data

    // tslint:disable-next-line: no-console
    console.log(access_url)

    // return something here to update the subscription state

    // updateSubscriptionState({
    //     iframeUrl: url,
    //     showIframe: true,
    // })
}

export const updateExistingSubscription = async (chargebeeData: IChargebeeDatabaseData, newPlanId: string) => {
    const chargeBeeUrl = 'https://us-central1-banner-preview-typescript.cloudfunctions.net/estimate_updateExistingSubscription'
    const requestConfig = {
        params: {
            chargebee_subscription_id: chargebeeData.customer.id,
            new_plan_id: newPlanId,
        },
    }

    const response = await axios.get(chargeBeeUrl, requestConfig)
    if (response instanceof Error) {
        throw new Error(response.message)
    }

    // tslint:disable-next-line: no-console
    console.log('response :', response);
    return response.data.estimate
}

export const updateAfterHostedPage = async (hostedPageId: string) => {
    const url = 'https://us-central1-banner-preview-typescript.cloudfunctions.net/retrieveHostedPageResult'
    const requestConfig = {
        params: {
            hosted_page_id: hostedPageId,
        },
    }

    const response = await axios.get(url, requestConfig)
    if (response instanceof Error) {
        throw new Error(response.message)
    }

    // tslint:disable-next-line: no-console
    console.log('response :', response);
    return response

}
