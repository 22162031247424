/**
 * Evaluate the transloadit response and return the uploaded assets
 * @param {TransloaditResponseObject} response Response object recieved
 * @returns {UploadsObject} The uploaded assets
 */
export const processUploadsResponse = (response:any) => {
    const { transloadit } = response
    const assemblies:any[] = []
    transloadit.forEach((assembly:any) => {
        assemblies.push(evaluateAssembly(assembly))
    })
    return assemblies
}

const evaluateAssembly = (assembly:any) => {
    const { assembly_id, bytes_usage, results } = assembly
    const originals:any[] = results[":original"]
    const { thumbnails, video_screenshots, extract } = results

    const assets:any[] = []
    originals.forEach((upload:any) => {

        // tslint:disable-next-line: no-console
        console.log("evaluating upload", upload)
        switch (true) {
            case upload.type === "image":
                // tslint:disable-next-line: no-console
                console.log("Got an image")
                assets.push(processImage(upload, thumbnails));
                return
            case upload.type === "video":
                assets.push(processVideo(upload, video_screenshots));
                return
            case upload.mime === "application/zip":
                assets.push(processZip(upload, extract));
                return
            default:
                return
        }
    })

    return { assembly_id, bytes_usage, assets }
}


const processImage = (upload: any, thumbnails:any[]) => {
    const { basename, original_name: originalName, original_id: originalId, meta, ssl_url: sslUrl, size } = upload
    const submittedTime = new Date()
    const thumbnailUrl:string = thumbnails.filter(x => originalId === x.original_id)[0].ssl_url

    return {
        basename,
        meta,
        originalId,
        originalName,
        size,
        sslUrl,
        submittedTime,
        thumbnailUrl,
    }

}

const processVideo = (upload: any, videoScreenshots: any[]) => {
    const { basename, original_name: originalName, original_id: originalId, meta, ssl_url: sslUrl, size } = upload
    const submittedTime = new Date()
    const thumbnailUrl: string = videoScreenshots.filter(x => originalId === x.original_id)[0].ssl_url

    return {
        basename,
        meta,
        originalId,
        originalName,
        size,
        sslUrl,
        submittedTime,
        thumbnailUrl,
    }

}

const processZip = (upload: any, extract: any[]) => {
    const { basename, original_name: originalName, original_id: originalId, meta, ssl_url: sslUrl, size } = upload
    const submittedTime = new Date()
    const thumbnailUrl: string = "https://via.placeholder.com/350x150"

    // Add all of the extracted files
    const files:object[] = []
    const htmlFiles: any[] = []
    extract.forEach(file => {

        if (originalId === file.original_id) {
            // tslint:disable-next-line: no-console
            console.log("Added to array", file);
            files.push(file)
            if (file.ext === "html") {
                // tslint:disable-next-line: no-console
                console.log('Got an HTML file', file)
                htmlFiles.push(file)
            }
        }
    })

    // Select the entry point html file
    meta.htmlEntryPoint = evaluateHtmlEntrypoint(htmlFiles)

    // Determine size (or assign default)
    // TODO - handle the default nicer
    const evaluatedDimensions = evaluateHtmlWidth(basename)
    meta.width = evaluatedDimensions.width
    meta.height = evaluatedDimensions.height

    return {
        basename,
        files,
        meta,
        originalId,
        originalName,
        size,
        sslUrl,
        submittedTime,
        thumbnailUrl,
    }

}

const evaluateHtmlEntrypoint = (htmlFiles:any[]) => {
// tslint:disable-next-line: no-console
    console.log('htmlFiles', htmlFiles)
    switch (true) {
        case htmlFiles.length === 1:
            // Just one html file, assume this is the entry point
            return htmlFiles[0].ssl_url
        case htmlFiles.length <= 1:
            // Multiple html files... evaluate for index.html
            const indexFiltered = htmlFiles.filter(x => x.name === "index.html")
            if (indexFiltered.length === 1) {
                return indexFiltered
            } else {
                // TODO - Handle this case
                // For now, return the first one in the array
                return htmlFiles[0]
            }
        case htmlFiles.length === 0:
            return "Nothing"
    }
}

const evaluateHtmlWidth = (basename:string) => {
    const dimensionsRegex = /\d+\x\d+/i;
    const sizeMatch:any[]|null = basename.match(dimensionsRegex);
    if (sizeMatch == null) {
        // tslint:disable-next-line: no-console
        console.log("Unable to determine dimensions of " + basename + ", enter this manually by editing the asset. The server will recognise dimensions from the file name upon upload.");
        return {
            height: "500",
            width: "500",
        }
    } else if (sizeMatch.length === 1) {
        // tslint:disable-next-line: no-console
        console.log("Got the dimensions of  " + basename + ", setting them now");
        return {
            height: sizeMatch[0].match(/x\d+/i).toString().replace("x", "").replace("X", ""),
            width: sizeMatch[0].match(/\d+x/i).toString().replace("x", "").replace("X", "")
        }
    } else {
        // tslint:disable-next-line: no-console
        console.log("Unable to determine dimensions of " + basename + ", enter this manually by editing the asset. The server will recognise dimensions from the file name upon upload.");
        return {
            height: "500",
            width: "500",
        }
    }
}
