import * as React from 'react'
import * as routes from '../constants/routes'
import { firebase } from '../firebase'

export const withAuth = (props: any, Component: any) => {

    firebase.auth.onAuthStateChanged(authUser => {
        if (!authUser) {
            props.history.push(routes.SIGN_IN);
        }
    });

    return (<Component props={props} />)
}
