import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import { auth, db } from "../../firebase";

interface IRegisterFormState {
  email: string;
  error: any;
  passwordOne: string;
  passwordTwo: string;
  firstName: string;
  secondName: string;
}

export const RegisterForm = (props:any) => {
  // tslint:disable-next-line: no-console
  const { history } = props

  const INITIAL_STATE: IRegisterFormState = {
    email: "",
    error: null,
    firstName: "",
    passwordOne: "",
    passwordTwo: "",
    secondName: "",
  }

  const [formState, setFormState] = useState(INITIAL_STATE)

  const { firstName, secondName, email, passwordOne, passwordTwo, error } = formState;

  const isInvalid: boolean =
    passwordOne !== passwordTwo ||
    passwordOne === "" ||
    email === "" ||
    firstName === "" ||
    secondName === "";

  const updateForm = (e:any) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  }

  const handleFormSubmit = (event:any) => {
    event.preventDefault()

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser: any) => {

        // Create a user in your own accessible Firebase Database too
        db.doCreateUser(authUser.user.uid, firstName, secondName, email)
          .then(() => {
            history.push(routes.HOME);
          })
          .catch(e => {
            setFormState({
              ...formState,
              error: e.message
            })
          });
      })
      .catch(e => {
        setFormState({
          ...formState,
          error: e.message
        })
      });
  }


  return (
    <div>
      <div className="text-center">
        <h1 className="h4 text-gray-900 mb-4">Register</h1>
      </div>
      <form className="user" onSubmit={(event) => handleFormSubmit(event)}>
        <div className="form-group">
          <input
            className="form-control form-control-user"
            value={firstName}
            name="firstName"
            onChange={updateForm}
            type="text"
            placeholder="First Name"
          />
        </div>
        <div className="form-group">
          <input
            className="form-control form-control-user"
            value={secondName}
            name="secondName"
            onChange={updateForm}
            type="text"
            placeholder="Last Name"
          />
        </div>
        <div className="form-group">
          <input
            className="form-control form-control-user"
            value={email}
            name="email"
            onChange={updateForm}
            type="text"
            placeholder="Email Address"
          />
        </div>
        <hr />
        <div className="form-group">
          <input
            className="form-control form-control-user"
            value={passwordOne}
            name="passwordOne"
            onChange={updateForm}
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="form-group">
          <input
            className="form-control form-control-user"
            value={passwordTwo}
            name="passwordTwo"
            onChange={updateForm}
            type="password"
            placeholder="Confirm Password"
          />
        </div>
        <button className="btn btn-primary btn-user btn-block" disabled={isInvalid} type="submit">
          Register
                </button>

        {error ? <p>Error: {error}</p> : null}

      </form>
      <hr />
      <div className="text-center">
        <Link className="small" to={routes.SIGN_IN}>Back to login page</Link>
      </div>
    </div>
  )
}
