import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import Transloadit from '@uppy/transloadit'
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../components/StateProvider'
import { UPPY_CONFIG } from '../../constants/uppy';
import { createNewProject } from '../../firebase/db';
import { withAuth } from "../../firebase/withAuth";
import { processUploadsResponse } from "../../modules/uploads";
import { IGlobalState } from '../../types'
import { ProjectAssetsList } from './ProjectAssetsList';

export const NewProjectComponent = () => {
    // const testObject = {"basename":"Screen Shot 2019-05-31 at 3.47.35 pm","meta":{"width":1597,"height":352,"aspect_ratio":"4.537","has_clipping_path":false,"frame_count":1,"colorspace":"RGB","average_color":"#180c4d"},"originalId":"704d0bb936804099b959df2256380be7","originalName":"Screen Shot 2019-05-31 at 3.47.35 pm.png","size":60049,"sslUrl":"https://creativesreviewassets.s3-ap-southeast-2.amazonaws.com/v0.1/{{projectId}}/:original/Screen Shot 2019-05-31 at 3.47.35 pm.png","submittedTime":"2019-05-31T05:54:36.098Z","thumbnailUrl":"https://creativesreviewassets.s3-ap-southeast-2.amazonaws.com/v0.1/{{projectId}}/thumbnails/Screen Shot 2019-05-31 at 3.47.35 pm.png"}

    const dashboardRow = React.createRef<HTMLDivElement>()

    const globalStateValue: any[] = useStateValue();
    const globalState: IGlobalState = globalStateValue[0]
    const dispatch: any = globalStateValue[1]

    // tslint:disable-next-line: no-console
    console.log(globalState, dispatch)

    // Hooks
    const [initialized, setInitialized] = useState(false)
    const [uppyDashboardWidth, updateWidth] = useState(0)
    const [projectAssets, updateAssets]:[any[], any] = useState([])

    const saveProject = () => {
        createNewProject("8hUT1YPn0FNcwLsS8uPxRM3QKjF3", {
            assets: projectAssets,
            id: "testy",
        })
    }

    useEffect(() => {
        //
    }, [projectAssets])

    // State
    useEffect(() => {
        if (!initialized) {
            updateWidth(dashboardRow.current ? dashboardRow.current.offsetWidth : 0)
        };
        setInitialized(true);
    }, [initialized, dashboardRow])

    // Uppy
    const uppy = Uppy()
    uppy.use(Transloadit, UPPY_CONFIG)
    uppy.on('complete', (result) => {
        const processedUploads:any = processUploadsResponse(result)
        // tslint:disable-next-line: no-console
        console.log('uppy on complete result', result)
        // tslint:disable-next-line: no-console
        console.log('processedUploads', processedUploads)

        processedUploads.forEach((upload:any) => {
            const { assets } = upload
            // tslint:disable-next-line: no-console
            console.log('assets', assets)
            updateAssets(projectAssets.concat(assets))
        })
    })

    return (
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Create new project</h1>
                <button onClick={saveProject} className="d-none d-sm-inline-block btn btn-md btn-primary shadow-sm">
                    Save Project&nbsp;&nbsp;
                    <i className="fas fa-download fa-sm text-white-50" />
                </button>
            </div>
            <div className="row">
                <div className="col-6">
                    <p className="mb-1">Title</p>
                    <h3 className="mb-0">Untitled project</h3>
                </div>
                <div className="col-6">
                    <p className="mb-1">Settings</p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12 mb-1">
                    Assets
                </div>
                <ProjectAssetsList projectAssets={projectAssets} />
            </div>
            <div ref={dashboardRow} className="row">
                <Dashboard
                    uppy={uppy}
                    height={300}
                    width={uppyDashboardWidth}
                />
            </div>
        </div>
    )
}

export const NewProject = (props: any) => withAuth(props, NewProjectComponent)
