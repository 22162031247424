import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


const config = {
  apiKey: "AIzaSyC07_Kw2vuAwCKIqjM-SF4FmYQUvUDH3CY",
  appId: "1:31666503754:web:a8bd9a0b40aff959",
  authDomain: "banner-preview-typescript.firebaseapp.com",
  databaseURL: "https://banner-preview-typescript.firebaseio.com",
  messagingSenderId: "31666503754",
  projectId: "banner-preview-typescript",
  storageBucket: "banner-preview-typescript.appspot.com",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
export const auth = firebase.auth();
export const firestore = firebase.firestore();
