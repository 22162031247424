import * as React from "react";
import { withAuth } from "../../firebase/withAuth";

export const HomeComponent = () => {
	return (
		<div className="container-fluid">
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h3 mb-0 text-gray-800">Home</h1>
			</div>
		</div>
	);
}

export const Home = (props: any) => withAuth(props, HomeComponent)
