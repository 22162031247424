import React from 'react'

export const ProfileComponent = (props: { data: any} ) => {

    const { email, firstName, secondName } = props.data.data

    return (
        <div className="col-6">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Your account</h6>
                </div>
                <div className="card-body">
                    <div>Your email: <b>{email}</b><hr /></div>
                    <div>First name: <b>{firstName}</b><hr /></div>
                    <div>Second name: <b>{secondName}</b></div>
                </div>
            </div>
        </div>
    )
}

export default ProfileComponent
