import React, { createContext, useContext, useReducer } from 'react';
import { IGlobalState } from '../types'
import { App } from './App';

export const StateContext = createContext([{}, {}]);
export const useStateValue = () => useContext(StateContext);
export const StateProvider = ({ reducer, initialState, children }:any) => {
    const usingReducer:any[] = useReducer(reducer, initialState)
    return (
        <StateContext.Provider value={usingReducer}>
            {children}
        </StateContext.Provider>
)};

export const StatefulApp = () => {
    const initialState = {
        activeProject: "string",
        authUser: null,
        chargebeeData: {
            card: null,
            customer: null,
            email: null,
            payment_source: null,
            subscription: null,
            uid: null,
        },
        firestoreUser: {
            data: {},
            requested: false,
        },
        loaded: false,
        menuActive: false,
        projectsList: [],
        theme: { primary: 'green' },
    } as IGlobalState;

    const mainReducer = (state: IGlobalState, action: any) => {
        // middleware goes here, i.e calling analytics service, etc.
        switch (action.type) {
            case 'applicationLoaded':
                return {
                    ...state,
                    loaded: true,
                }
            case 'toggleMenu':
                return {
                    ...state,
                    menuActive: !state.menuActive
                };
            case 'updateAuthUser':
                return {
                    ...state,
                    authUser: action.authUser
                };
            case "processUserSignOut":
                return {
                    ...initialState,
                    loaded: true,
                }
            case 'updateProjectsList':
                // tslint:disable-next-line: no-console
                console.log('update projects list user', state)
                return {
                    ...state,
                    projectsList: action.projectsList
                };
            case 'updateUserData':
                return {
                    ...state,
                    firestoreUser: {
                        data: action.data,
                        requested: true
                    }
                }
            case 'updateSubscriptionData':
                return {
                    ...state,
                    chargebeeData: action.data
                }
            default:
                return state;
        }
    };

    return (
        <StateProvider initialState={initialState} reducer={mainReducer}>
            <App />
        </StateProvider>
    );
}
