// import axios from 'axios'
import React, { useState } from 'react'
import { useStateValue } from '../../components/StateProvider'
import { SUBSCRIPTIONS } from '../../constants/subscriptions'
import { withAuth } from "../../firebase/withAuth";
import { getSubscriptionUrl, updateAfterHostedPage, updateExistingSubscription } from '../../modules/chargebee'
// import { IChargebeeDatabaseData } from '../../types'
// import { ChargebeeIframe } from './ChargebeeIframe';
import AccountComponent from './AccountComponent'
import ProfileComponent from './ProfileComponent'
import SubscriptionCards from './SubscriptionCards'

export const SubscriptionComponent = () => {
    // Global state
    const stateValue: any[] = useStateValue();
    const state: any = stateValue[0]
    const { chargebeeData, firestoreUser } = state

    // tslint:disable-next-line: no-console
    console.log('firestoreUser', firestoreUser)

    // Chargbee iframe state
    const emptyIframeState = { showIframe: false as boolean, iframeUrl: "" as string };
    const [SubscriptionIframeState, updateSubscriptionIframeState] = useState(emptyIframeState);

    // Subscription change overlay
    // const emptySubscriptionChangeState = { show: false as boolean, data: {} as any };
    // const [subscriptionChangeState, updateSubscriptionChangeState] = = useState(emptySubscriptionChangeState);

    // Hosted page checks
    const [hostedPageChecks, checkedHostedPage] = useState([] as string[]);


    const closeIframe = (): void => updateSubscriptionIframeState(emptyIframeState)

    // Listens for the
    const handleSubscription = async (planId: string): Promise<void> => {
        if (chargebeeData.subscription && chargebeeData.subscription.status === "active") {
            // tslint:disable-next-line: no-console
            console.log('Subscription is active', chargebeeData)
            const estimate = await updateExistingSubscription(chargebeeData, planId)
            const { invoice_estimate: invoiceEstimate } = estimate
            // tslint:disable-next-line: no-console
            console.log(invoiceEstimate)
            // tslint:disable-next-line: no-console
            console.log('By upgrading now, we will charge an additional $' + invoiceEstimate.amount_due / 100)
            // tslint:disable-next-line: no-console
            console.log('Moving forward, you will be charged $', SUBSCRIPTIONS.filter(x => x.id === planId)[0].price / 100 + " monthly")
            // tslint:disable-next-line: no-console
            console.log('Your next payment will be on: ', new Date(chargebeeData.subscription.next_billing_at * 1000))



        } else {
            const chargbeeUrl = await getSubscriptionUrl(chargebeeData, planId);
            // tslint:disable-next-line: no-console
            console.log('chargebeeUrl', chargbeeUrl)
            updateSubscriptionIframeState({
                iframeUrl: chargbeeUrl,
                showIframe: true
            })
        }
    }

    const evalIframeMessage = async (e: any) => {
        const { data, origin } = e;
        // tslint:disable-next-line: no-console
        // console.log('eval!!!', e)
        if (origin !== "https://absorbdigital-test.chargebee.com" ) {
            return;
        }

        switch (true) {
            case data === "cb.close":
                closeIframe()
                return;
            case data.key === "cb.success":
                const subscriptionId:string = e.data.value

                if (!hostedPageChecks.includes(subscriptionId)) {
                    const updatedArray = [...hostedPageChecks, subscriptionId]
                    // tslint:disable-next-line: no-console
                    console.log(hostedPageChecks)
                    checkedHostedPage(updatedArray)
                    updateAfterHostedPage(subscriptionId);
                    // tslint:disable-next-line: no-console
                    console.log('Got success!!', subscriptionId)
                } else {
                    // tslint:disable-next-line: no-console
                    console.log('Already checked this hosted page')
                }
                return;
            default:
                return;
        }
    }

    window.addEventListener('message', evalIframeMessage);

    return (
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0">Your Account</h1>
                {hostedPageChecks}

            </div>
            <div className="row">
                <AccountComponent data={firestoreUser} />
                <ProfileComponent data={chargebeeData} />
            </div>

            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0">Manage Subscription</h1>
            </div>
            <SubscriptionCards handleSubscription={handleSubscription} chargebeeData={chargebeeData} />
            {SubscriptionIframeState.showIframe
                ? <div className="iframe-background">
                    <div className="iframe-wrapper">
                        <iframe title="Chargebee" src={SubscriptionIframeState.iframeUrl} />
                    </div>
                    <button onClick={closeIframe} className="btn btn-primary btn-lg">Close X</button>
                </div>
                : null}
        </div>
    )
}

export const Subscription = (props: any) => withAuth(props, SubscriptionComponent)
