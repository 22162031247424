import * as React from 'react';
import { BrowserRouter, Route, Switch,  } from 'react-router-dom';
import * as routes from '../constants/routes';
import { db, firebase } from '../firebase';
// import { withAuthentication } from '../firebase/withAuthentication';
import { Account } from '../pages/Account';
import { Home } from '../pages/Home';
import { NewProject } from '../pages/NewProject';
import { NoMatch } from "../pages/NoMatch";
import { PasswordForget } from '../pages/PasswordForget';
import { Register } from '../pages/Register';
import { SignIn } from '../pages/SignIn';
import { Subscription } from '../pages/Subscription';
import { IGlobalState } from '../types'
import { DashboardWrapper } from './DashboardWrapper';
import { useStateValue } from './StateProvider';

export const App = () => {
	// Global state
	const globalStateValue: any[] = useStateValue();
	const globalState: IGlobalState = globalStateValue[0]
	const dispatch: any = globalStateValue[1]

	firebase.auth.onAuthStateChanged(authUser => {
		if (authUser && globalState.authUser !== authUser) {
			dispatch({ type: "updateAuthUser", authUser })
			getUserData(authUser.uid)
		}

		if (!globalState.loaded) {
			dispatch({ type: "applicationLoaded" })
		}
	});

	const getUserData = async (userId: string) => {
		if (!globalState.firestoreUser || !globalState.firestoreUser.requested) {
			db.watchFirestore(userId, dispatch)
		}
	}

	// Used to watch for updates
	React.useEffect(() => {
		//
	}, [globalState])

	if (!globalState.loaded) {
		return <div>Loading...</div>
	} else {
		return (
			<BrowserRouter>
				<Switch>
					<Route exact={true} path={routes.REGISTER} component={Register} />
					<Route exact={true} path={routes.SIGN_IN} component={SignIn} />
					<Route exact={true} path="/" component={SignIn} />
					<DashboardWrapper>
						<Route exact={true} path={routes.FORGOT_PASSWORD} component={PasswordForget} />
						<Route exact={true} path={routes.HOME} component={Home} />
						<Route exact={true} path={routes.ACCOUNT} component={Account} />
						<Route exact={true} path={routes.NEW_PROJECT} component={NewProject} />
						<Route exact={true} path={routes.SUBSCRIPTION} component={Subscription} />
					</DashboardWrapper>
					<Route component={NoMatch} />
				</Switch>
			</BrowserRouter>
		)
	}
}
