import { firestore } from "./firebase";

// User API
export const doCreateUser = (id: string, firstName: string, secondName:string, email: string) => {
  return firestore.collection("users").doc(id).set({
    email,
    firstName,
    secondName
  });
}

export const onceGetUsers = () => firestore.collection("users");

export const createNewProject = (userId: string, project: any) => {
  firestore.collection("users").doc(userId).collection("projects").doc().set({
    project
  }).then(() => {
    return { projectId: project }
  })
  // TODO - Catch error here
}

export const watchFirestore = (userId:string, dispatchFunction:any) => {
  let receivedData = false;
  const docRef = firestore.collection("users").doc(userId)
  docRef.onSnapshot((doc: any) => {
    // Data has been updated with a new snapshot, get the data
    const data = doc.data();

    // tslint:disable-next-line: no-console
    console.log('Got user data snapshot', data)

    if (data) {
      // Update the global state with updated user data
      dispatchFunction({ type: "updateUserData", data })
      const { chargebee_subscription_id } = data

      // Check to make sure we've recieved data and that the chargebee subscription id exists
      if (!receivedData && chargebee_subscription_id) {
        watchSubscriptionDocument(chargebee_subscription_id)
        receivedData = true;
      }
    } else {
      // tslint:disable-next-line: no-console
      console.error('Data is undefined - something went wrong')
    }

  });

  const watchSubscriptionDocument = (id:string) => {
    const subscriptionDocRef = firestore.collection("subscriptions").doc(id)
    subscriptionDocRef.onSnapshot((doc: any) => {
      // tslint:disable-next-line: no-console
      console.log("Subscription data updated: ", doc.data());
      const data = doc.data();
      dispatchFunction({ type: "updateSubscriptionData", data })
    })
  }
}
