import * as React from 'react';
import * as routes from '../../constants/routes';
import { auth } from '../../firebase';

interface IInterfaceProps {
  email?: string;
  error?: any;
  history?: any;
  password?: string;
}

interface IInterfaceState {
  email: string;
  error: any;
  password: string;
}

export class SignInForm extends React.Component<IInterfaceProps, IInterfaceState> {
  private static INITIAL_STATE = {
    email: '',
    error: null,
    password: ''
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: IInterfaceProps) {
    super(props);

    this.state = { ...SignInForm.INITIAL_STATE };
  }

  public onRequestGoogleSignIn = () => {
    // auth.doSignInWithGoogleAuth()
    //   .then(response => {
    //   })
    //   .catch(error => {
    //   })
  };

  public onSubmit = (event: any) => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...SignInForm.INITIAL_STATE }));
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(SignInForm.propKey('error', error));
      });

    event.preventDefault();
  };

  public render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div>
        <div className="text-center">
          <h1 className="h4 text-gray-900 mb-4">Welcome!</h1>
        </div>

        <form className="user" onSubmit={event => this.onSubmit(event)}>
          <div className="form-group">
            <input className="form-control form-control-user" value={email} onChange={event => this.setStateWithEvent(event, 'email')} type="text" placeholder="Email Address" />
          </div>
          <div className="form-group">
            <input className="form-control form-control-user" value={password} onChange={event => this.setStateWithEvent(event, 'password')} type="password" placeholder="Password" />
          </div>
          <button className="btn btn-primary btn-user btn-block" disabled={isInvalid} type="submit">
            Sign In
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(SignInForm.propKey(columnType, (event.target as any).value));
  }
}
