export const SUBSCRIPTIONS = [
    {
        description: "tbc",
        id: "freelancer",
        prettyName: "Freelancer",
        price: 1900,
    },
    {
        description: "tbc",
        id: "studio",
        prettyName: "Studio",
        price: 3700,
    },
    {
        description: "tbc",
        id: "agency",
        prettyName: "Agency",
        price: 5900,
    }
]
