import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import * as routes from "../../constants/routes";
import { RegisterForm } from "./Register";

class RegisterComponent extends React.Component<RouteComponentProps<any, {}, any>> {

  public componentDidMount() {

// tslint:disable-next-line: no-console
    console.log("Component did mount");
  }


  public render() {

    return (
      <div className="bg-gradient-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-register-image" />
                    <div className="col-lg-6">
                      <div className="p-5">
                        <RegisterForm history={this.props.history} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const RegisterLink = () => (
  <div className="text-center">
    <Link className="small" to={routes.REGISTER}>Register</Link>
  </div>
);

export const Register = withRouter(RegisterComponent);
