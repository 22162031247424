import React from 'react'
import { IChargebeeDatabaseData } from '../../types'

export const ProfileComponent = (props: { data: IChargebeeDatabaseData} ) => {
    const { subscription, card, customer } = props.data

    const processDate = (date: number) :string => new Date(date * 1000).toLocaleDateString()

    if (!subscription) {
        return null
    } else {
        return (
            <div className="col-6">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Billing details</h6>
                    </div>
                    <div className="card-body">
                        {customer ? <div>Your email: <b>{customer.email}</b><hr /></div> : <div>Loading...</div>}
                        {subscription ? <div>Your plan: <b>{subscription.plan_id}</b><hr /></div> : null}
                        {subscription ? <div>Status: <b>{subscription.status}</b><hr /></div> : null}
                        {subscription ? <div>Next billing date: <b>{processDate(subscription.next_billing_at)}</b></div> : null}
                        {card ? <div><hr />Card active: {card.masked_number}</div> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileComponent
