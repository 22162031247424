export const UPPY_CONFIG = {
    alwaysRunAssembly: false,
    fields: {},
    importFromUploadURLs: false,
    params: {
        auth: { key: '63e00c10061b11e699b027f78724804e' },
        steps: {
            export: {
                // eslint-disable-next-line
                path: "v0.1/BANNER_PREVIEW/${previous_step.name}/${file.original_path}/${file.name}"
            }
        },
        template_id: 'a2d62420061d11e6a91e91f5bf5738cb',
    },
    service: 'https://api2.transloadit.com',
    signature: null,
    waitForEncoding: true,
    waitForMetadata: true,
};
