import React from "react";
import { Navigation } from "./Navigation";
import { Sidebar } from "./Sidebar";

export const DashboardWrapper = (props:any) => (
    <div id="page-top">
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <Navigation />
                <div className="container-fluid">
                    {props.children}
                </div>
            </div>
        </div>
    </div>
)
