import * as React from "react";
import { withAuth } from "../../firebase/withAuth";

export const AccountComponent = () => (

        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Account</h1>
            </div>
        </div>

);
export const Account = (props: any) => withAuth(props, AccountComponent)
