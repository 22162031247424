import React from "react";
import { Link } from "react-router-dom";
import * as routes from "../constants/routes";
import { SignOutButton } from "./SignOutButton";
import { useStateValue } from './StateProvider';

export const Sidebar = () => {
  const stateValue = useStateValue();
  const dispatch: any = stateValue[1]
  const state:any = stateValue[0]
  const { menuActive } = state

  const toggleSidebar = () => {
      dispatch({ type: "toggleMenu" })
  }

  return (
    <ul className={`navbar-nav sidebar sidebar-dark bg-gradient-primary accordion ${menuActive ? 'toggled' : ''}`} id="accordionSidebar">

      <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={routes.HOME}>
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink" />
        </div>
        <div className="sidebar-brand-text mx-3">Banner Reviews</div>
      </Link>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        <Link to={routes.HOME} className="nav-link">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>Projects</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to={routes.NEW_PROJECT} className="nav-link">
          <i className="fas fa-fw fa-plus-square" />
          <span>Create a new project</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to={routes.ACCOUNT} className="nav-link">
          <i className="fas fa-fw fa-cogs" />
          <span>Account settings</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to={routes.SUBSCRIPTION} className="nav-link">
          <i className="fas fa-fw fa-money-check" />
          <span>Billing / Subscription</span>
        </Link>
      </li>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        <SignOutButton />
      </li>

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button onClick={toggleSidebar} className="rounded-circle border-0" id="sidebarToggle" />
      </div>

      <hr className="sidebar-divider" />
    </ul>

  )
}
