import * as React from 'react';
// import * as routes from '../../constants/routes';
// import { auth } from '../../firebase';

interface IInterfaceProps {
  email?: string;
  error?: any;
  history?: any;
  password?: string;
}

interface IInterfaceState {
  email: string;
  error: any;
  password: string;
}

export class SocialSignIn extends React.Component<IInterfaceProps, IInterfaceState> {
  private static INITIAL_STATE = {
    email: '',
    error: null,
    password: ''
  };

  // private static propKey(propertyName: string, value: any): object {
  //   return { [propertyName]: value };
  // }

  constructor(props: IInterfaceProps) {
    super(props);

    this.state = { ...SocialSignIn.INITIAL_STATE };
  }

  public onRequestGoogleSignIn = () => {
    // auth.doSignInWithGoogleAuth()
    //   .then(response => {
    //   })
    //   .catch(error => {
    //   })
  };

  public render() {
    // const { email, password, error } = this.state;

    // const isInvalid = password === '' || email === '';

    return (
      <div>
          <button onClick={this.onRequestGoogleSignIn} className="btn btn-primary btn-google btn-user btn-block">
            <i className="fab fa-google fa-fw" /> Login with Google
          </button>
          <button className="btn btn-primary btn-facebook btn-user btn-block">
            <i className="fab fa-facebook-f fa-fw" /> Login with Facebook
          </button>
      </div>
        );
      }

  // private setStateWithEvent(event: any, columnType: string): void {
  //     this.setState(SignInForm.propKey(columnType, (event.target as any).value));
  //   }
  }
