import * as React from "react";
import { auth } from "../firebase";
import { useStateValue } from './StateProvider';


export const SignOutButton = () => {
  const stateValue: any[] = useStateValue();
  const dispatch: any = stateValue[1]

  const handleSignOut = () => {
    auth.doSignOut()
      .then(() => {
        dispatch({type: "processUserSignOut"})
      })
  }

  return (
    <button className="nav-link" onClick={handleSignOut}>
      <i className="fas fa-fw fa-sign-out-alt" />
      <span>Sign out</span>
    </button>
  )
}
