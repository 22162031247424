import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { PasswordForgetLink } from '../PasswordForget';
import { RegisterLink } from '../Register';
import { SignInForm } from './SignInForm';
import { SocialSignIn } from './SocialSignIn';

const SignInComponent = ({ history }: { [key: string]: any }) => (
  <div className="fullscreen-background bg-gradient-primary">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                <div className="col-lg-6">
                  <div className="p-5">
                    <SignInForm history={history} />
                    <hr />
                    <SocialSignIn />
                    <hr />
                    <RegisterLink />
                    <PasswordForgetLink />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const SignIn = withRouter(SignInComponent);
