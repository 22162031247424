import React from 'react'

export const ProjectAssetsList = (props:{projectAssets: any[]}) => {
    const assetsOnPage: any = []
    const { projectAssets } = props
    if (projectAssets.length > 0) {
        projectAssets.forEach((x: any) => {
            assetsOnPage.push(
                <div key={x.id} className="col-12 mb-3">
                    <div className="card border-left-primary shadow h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">IMAGE</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{x.basename}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-calendar fa-2x text-gray-300" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        })
        return assetsOnPage
    } else {
        return null
    }

}
